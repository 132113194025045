@import "../../../assets/styles/index.scss";

.page {
  min-height: calc(100vh - 160px);
  height: auto;
  padding-top: 140px;
  padding-bottom: 20px;
  &__pending-profile {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  &__pending-message {
    margin-left: 30px;
    line-height: 0px;
    text-align: center;
    color: $color-text-gray;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 15px;
  }
  &__container {
    height: fit-content;
    background-color: $color-bg-white;
    box-shadow: $color-box-shadow-sidebar;
    border-radius: 16px;
    padding-top: 20px;
    padding-bottom: 15px;
    &--small {
      margin-left: 290px;
      margin-right: 30px;
      transition: 0.5s;
    }

    &--expand {
      margin-left: 140px;
      margin-right: 30px;
      transition: 0.5s;
    }

    &--belowDiv {
      margin-top: 30px;
    }

    &__header {
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 10px;
    }
    &__table-div {
      margin-left: 30px;
      margin-right: 30px;

      @include dynamicMax(1307px) {
        overflow-x: scroll;
      }
    }

    &__no-match {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 15px;
      color: $color-text-dark-gray;
    }

    &__pagination-div {
      margin-left: 30px;
      margin-right: 30px;
    }

    &__input-label {
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 15px;
      color: $color-text-dark-gray;
    }
  }

  &__input-field {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 10px 16px;
    height: 40px;
    background: $color-bg-white;
    border: 1px solid $color-border-slate-gray;
    border-radius: 10px;
    margin-top: 12px;
    margin-bottom: 16px;
    color: $color-text-dark-gray;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 14px;

    &:focus {
      outline: none;
      border: 1px solid $color-bg-light-sea-green;
      box-shadow: $color-box-shadow-search;
    }
  }

  &__form-error {
    color: $color-text-red;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  &__field-addBtnDiv {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 6px;
  }

  &__form-btnDiv {
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__submitBtn {
    height: 48px;
    padding-left: 21px;
    padding-right: 21px;
    box-shadow: $color-box-shadow-icon-div;
    border-radius: 20px;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      transform: scale(1.01);
      box-shadow: $color-box-shadow-loginBtn;
    }

    &--fill {
      background: $color-bg-button-fill;
      border: none;
      color: $color-bg-white;
    }

    &--transparent {
      box-sizing: border-box;
      background: $color-bg-white;
      border: 1px solid $color-bg-light-sea-green;
      color: $color-text-sea-green;
      margin-left: 12px;
    }
  }

  &__formBtn-icon {
    font-size: 20px;
    padding-top: 4px;
    margin-right: 10px;
    &--refresh {
      color: $color-bg-white;
    }
  }
}
