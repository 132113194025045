@import "../../assets/styles/index.scss";

.navigation {
  $navigation: &;
  &__header {
    font-family: $font-family-poppins;
    position: fixed;
    top: 0;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-bg-white;
    padding: 0 1rem;
    transition: 0.5s;
    z-index: 100;
    padding-left: 140px;
    left: 0;
    box-shadow: $color-box-shadow-header;
    &--fold {
      padding-left: 290px;
    }
    &__body {
      display: flex;
      width: 100%;
    }
  }

  &__header-right {
    width: 53%;
    display: flex;
    justify-content: end;
    align-items: center;
    position: fixed;
    right: 30px;
    text-decoration: none;
    margin-top: 5px;
  }

  &__toggle {
    font-size: 1.25rem;
    cursor: pointer;
    color: $color-text-sea-green;

    &__menu-div {
      display: flex;
      align-items: center;
    }
  }

  &__menu-text {
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: 16px;
    line-height: 24px;
    color: $color-text-sea-green;
    height: 24px;
    margin-left: 11px;
  }

  &__top-link-list {
    width: 238px;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    text-align: center;
    padding-top: 5px;
    margin-right: 10px;
    @include dynamicMax(944px) {
      display: none;
    }
  }

  &__top-nav-link {
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 14px;
    color: $color-text-black;
    text-decoration: none;

    &:hover {
      font-weight: $font-weight-bold;
    }

    &--active {
      font-weight: $font-weight-bold;
    }
  }
  &__profile-div {
    display: flex;
  }

  &__profile-text-div {
    height: 42px;
    width: 140px;
    text-align: end;

    &__text {
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-medium;
      font-size: 16px;
      color: $color-text-sea-green;
      line-height: 4px;
    }

    &__sub-text {
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 14px;
      color: $color-text-gray;
      line-height: 0px;
    }
  }

  &__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 110px;
    padding-top: 1rem;
    transition: 0.5s;
    z-index: 100;
    background: $color-bg-sidebar;
    box-shadow: $color-box-shadow-sidebar;
    overflow: hidden;
    &--show {
      width: 260px;
    }
  }

  &__app-div {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    transition: 0.5s ease;
  }
  &__logo {
    height: 50px;
    &--small{
      height: 30px;
    }
  }
  &__drop-arrow {
    position: relative;
    left: 210px;
    top: -15px;
    cursor: pointer;
    color: $color-bg-white;
    font-size: 15px;
    &--active {
      color: $color-bg-lighter-sea-green;
    }
    &--rotate {
      transform: rotate(90deg);
    }
  }
  &__nav-link {
    color: $color-text-white;
    transition: 0.5s;
    margin-bottom: -15px;
    margin-left: 13px;
    margin-right: 13px;
    align-items: center;
    text-decoration: none;
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 30.5px;
    padding: 2px 0 2px 0;
    &:hover {
      background: $color-bg-sidebar-hover;
      border-radius: 32px;
      color: $color-bg-lighter-sea-green;
    }
    &:hover ~ #{$navigation}__drop-arrow {
      color: $color-bg-lighter-sea-green;
    }

    &--active {
      background: $color-bg-sidebar-hover;
      border-radius: 32px;
      color: $color-bg-lighter-sea-green;
    }
  }

  &__nav-link-icon {
    font-size: 23px;
    cursor: pointer;
    margin-left: 31.5px;
    margin-top: 5px;
  }

  &__nav-link-name {
    font-family: $font-family-poppins;
    font-weight: $font-weight-medium;
    display: none;
    transition: 0.5s;

    &--show {
      display: block;
      transition: 0.5s;
    }
  }

  &__sub-fields {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    margin-right: 20px;
    position: relative;
    top: -7px;
  }

  &__sub-link {
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 15px;
    line-height: 22px;
    color: $color-text-white;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 19px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-decoration: none;

    &:hover {
      color: $color-bg-lighter-sea-green;
    }

    &--active {
      color: $color-bg-lighter-sea-green;
    }
  }
}
