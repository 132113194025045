@import "../../../assets/styles/index.scss";

.header {
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;

  @include dynamicMax(1250px) {
    display: inline;
  }

  &--add-page {
    @include dynamicMax(1250px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__left {
    min-width: 20%;
    width: auto;
    display: flex;
    height: 100%;
    align-items: center;

    @include dynamicMax(1250px) {
      width: 100%;
      margin-bottom: 10px;
    }

    &--extra-btn {
      width: 68%;
      justify-content: space-between;

      @include dynamicMax(1250px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  &__right {
    width: 100%;
    margin-left: 10%;
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include dynamicMax(1250px) {
      width: 100%;
    }

    &--only-btn {
      display: flex;
      width: 100%;
      justify-content: end;
      padding-bottom: 5px;
    }

    &--extra-btn {
      justify-content: space-between;

      @include dynamicMax(1250px) {
        width: 100%;
      }
    }
  }

  &__title-div {
    width: fit-content;
    height: 100%;
    line-height: 0px;

    &__title {
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-medium-plus;
      font-size: 18px;
      line-height: 7px;
      color: $color-text-fade-black;
    }

    &__subTitle {
      font-weight: $font-weight-medium;
      font-size: 14px;
      line-height: 15px;
      margin-top: -10px;
      color: $color-text-gray;
      font-family: $font-family-poppins;
    }
  }

  &__search-div {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 40px;

    @include dynamicMax(1250px) {
      margin-left: -14px;
    }

    &--report {
      margin-right: 60px;
    }

    &--left {
      margin-left: -14px;
    }

    &--large {
      width: 300px;
    }
  }

  &__search-field {
    width: 100%;
    height: 40px;
    background: $color-bg-white;
    border: 0.5px solid $color-border-dark-gray;
    border-radius: 12px;
    padding-left: 35px;
    font-family: $font-family-noto-sans-jp;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 12px;
    line-height: 18px;
    color: $color-text-dark-gray;

    &:hover {
      outline: none;
      box-shadow: $color-box-shadow-search;
    }

    &:focus {
      outline: none;
      border: 1px solid $color-bg-light-sea-green;
      box-shadow: $color-box-shadow-search;
    }

    &::placeholder {
      font-family: $font-family-noto-sans-jp;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 12px;
      line-height: 18px;
      color: $color-border-dark-gray;
    }
  }

  &__search-icon {
    position: relative;
    width: 14px;
    height: 14px;
    left: 28px;
    bottom: 3px;
    cursor: pointer;
    color: $color-border-dark-gray;
    transition: 0.8s;

    &--active {
      color: $color-bg-light-sea-green;
    }
  }

  &__exportBtn {
    $exportBtn: &;
    height: 40px;
    background: $color-bg-button-inActive;
    box-shadow: $color-box-shadow-icon-div;
    border-radius: 10px;
    color: $color-text-white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding-bottom: 5px;
    cursor: default;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 40px;

    &--active {
      background-color: $color-bg-button-fill;
      cursor: pointer;

      &:hover {
        transform: scale(1.01);
        box-shadow: $color-box-shadow-loginBtn;

        #{$exportBtn}__apply-icon {
          margin-top: -1px;
        }
      }
    }
  }

  &__export-icon {
    width: 18px;
    height: 18px;
    margin-right: 7.5px;
    margin-top: 3px;
  }

  &__addBtn {
    box-sizing: border-box;
    height: 40px;
    background: $color-bg-white;
    border: 0.5px solid $color-bg-light-sea-green;
    box-shadow: $color-box-shadow-icon-div;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    text-decoration: none;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: 14px;
    color: $color-text-sea-green;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      transform: scale(1.01);
      box-shadow: $color-box-shadow-loginBtn;
    }
  }

  &__addBtn-icon {
    font-size: 20px;
    padding-top: 8px;
    margin-right: 2px;
  }

  &__updateBtn-icon {
    font-size: 20px;
    padding-top: 5px;
    margin-right: 4px;
  }

  &__filter {
    width: 165px;
    height: 34px;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    color: $color-text-gray;
    font-size: 14px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: $color-bg-white;
    box-shadow: $color-box-shadow-filter-btn;
    border-radius: 8px;
    &__button {
      display: flex;
      align-items: center;
      height: 100%;
      width: 148px;
    }

    &__card {
      padding-bottom: 20px;
    }
  }

  &__filter-icon {
    width: 16px;
    height: 10.08px;
    margin-left: 17px;
    cursor: pointer;
  }
}