@import "../../../assets/styles/index.scss";

.page {
  &__profileDiv {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    padding-bottom: 15px;
  }

  &__profile-container {
    width: 100%;
    display: flex;
    &--user-profile {
      margin-bottom: -20px;
    }
  }

  &__profile-left {
    width: 32%;
  }
  &__profile-segment {
    width: 100%;
    line-height: 5px;
    margin-bottom: 35px;

    &__profile-label {
      font-family: $font-family-poppins;
      font-weight: $font-weight-medium;
      font-size: 15px;
      line-height: 10px;
      letter-spacing: 0.02em;
      color: $color-text-dark-gray;
    }

    &__profile-text {
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: $color-text-light-gray;
      word-wrap: break-word;
      margin-top: 5px;
      font-size: 14px;
    }
  }

  &__profile-right {
    width: 28.5%;
    padding-left: 20px;

    &--arabic {
      padding-right: 20px;
      padding-left: 0px;
    }
  }

  &__profile-edit {
    width: 100%;
  }
  &__data-form {
    margin: 10px 30px 30px 30px;
  }
  &__update-profileBtnImg {
    width: 14.38px;
    height: 13.13px;
    margin-right: 8.27px;
  }
}
