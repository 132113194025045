@import "../../assets/styles/index.scss";
.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__body {
    display: flex;
    width: 300px;
    padding-left: 10px;
    align-items: center;
  }
  &__body-left {
    border-right: 1.5px solid $color-border-gray;
    height: 40px;
    padding-right: 10px;
    margin-right: 10px;
    &__code {
      font-family: $font-family-poppins;
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 3px;
      color: $color-text-black;
    }
  }
  &__right-text {
    font-family: $font-family-poppins;
    font-size: 14px;
    font-weight: $font-weight-regular;
    color: $color-text-gray;
  }
}
