@import "../../../assets/styles/index.scss";

body {
  overflow-x: hidden;
  background: $color-bg-body;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    width: 430px;
    background: $color-bg-white;
    border-radius: 8px;
    box-shadow: $color-box-shadow-login;

    &__error-message {
      color: $color-text-red;
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 12px;
      margin-top: -20px;
      margin-bottom: 20px;
    }

  }

  &__app-head {
    display: flex;
    align-items: center;

    &__app-logo {
      margin-top: 20px;
      margin-bottom: 10px;
      height: 50px;
    }
  }

  &__app-title {
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-medium-plus;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $color-text-black;
    margin-left: 5px;
    padding-bottom: 5px;
  }

  &__body {
    width: 85%;
    line-height: 0px;
    margin-bottom: 30px;

    &__title {
      font-family: $font-family-poppins;
      font-style: normal;
      font-size: 20px;
      font-weight: $font-weight-bold;
      color: $color-text-black;
      line-height: 5px;
    }

    &__sub-title {
      font-family: $font-family-poppins;
      font-style: normal;
      font-size: 14px;
      font-weight: $font-weight-regular;
      color: $color-text-gray;
      line-height: 5px;
    }

    &__form {
      margin-top: 30px;
    }

    &__input-label {
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 15px;
      color: $color-text-dark-gray;
    }

    &__input-field {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 10px 10px 16px;
      height: 40px;
      background: $color-bg-white;
      border: 1px solid $color-border-slate-gray;
      border-radius: 8px;
      margin-top: 12px;
      margin-bottom: 10px;
      color: $color-text-dark-gray;
      font-family: $font-family-poppins;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: 14px;

      &:focus {
        outline: none;
        border: 1px solid $color-bg-light-sea-green;
        box-shadow: $color-box-shadow-search;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: none;
    background: $color-bg-button-fill;
    border-radius: 8px;
    cursor: pointer;
    color: $color-text-white;

    &:hover {
      transform: scale(1.01);
      box-shadow: $color-box-shadow-loginBtn;
    }
  }

  &__option {
    font-family: $font-family-poppins;
    font-style: normal;
    font-size: 14px;
    font-weight: $font-weight-regular;
    color: $color-text-gray;
    line-height: 5px;

    &__btn {
      color: $color-text-blue;
      cursor: pointer;
    }
  }

  &__form-error {
    color: $color-text-red;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}