@mixin dynamicMax($breakPoint) {
  @media only screen and (max-width: #{$breakPoint}) {
    @content;
  }
}

@mixin dynamicMin($breakPoint) {
  @media only screen and (min-width: #{$breakPoint}) {
    @content;
  }
}
