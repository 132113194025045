@import "../../../assets/styles/index.scss";

.filter {
    position: relative;
    width: 296px;
    right: 150px;
    top: 6px;
    background: $color-bg-white;
    box-shadow: $color-box-shadow-filter;
    border-radius: 12px;
    z-index: 2;
    height: 150px;
    padding-top: 15px;

    &--medium {
        height: 205px;
    }

    &__section {
        height: 51px;
        margin-left: 14px;
        margin-top: 10px;

        &--activity-count {
            width: 240px;
        }

        &--checkbox {
            width: 150px;
        }

        &--admin-checkbox {
            width: 180px;
        }

        &__label {
            font-family: $font-family-poppins;
            font-style: normal;
            font-weight: $font-weight-regular;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: $color-text-dark-gray;
            margin-right: 5px;
        }

        &__actions {
            display: flex;
            margin-top: 3px;
            justify-content: space-between;
        }
    }

    &__element-btn {
        height: 27px;
        border: 0.5px solid $color-border-gray;
        border-radius: 12px;
        text-align: center;
        color: $color-text-gray;
        background-color: $color-bg-white;
        cursor: pointer;
        padding: 2px 15px;
        font-family: $font-family-poppins;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: 12px;
        line-height: 0px;

        &:hover {
            background-color: $color-bg-light-sea-green;
            border: 0.5px solid $color-bg-light-sea-green;
            color: $color-text-black;
        }

        &--fill {
            background-color: $color-bg-light-sea-green;
            border: 0.5px solid $color-bg-light-sea-green;
            color: $color-text-black;
        }
    }

    &__check-element {
        display: flex;
        align-items: center;
        font-family: $font-family-poppins;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: 12px;
        line-height: 0px;
        color: $color-text-gray;

        &__vector-icon {
            width: 10.25px;
            height: 8.01px;
        }
    }

    &__checkbox {
        box-sizing: border-box;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &--fill {
            text-align: center;
            background: $color-text-sea-green;
            border: 1px solid $color-text-sea-green;
        }

        &--empty {
            border: 1px solid $color-border-dark-gray;
        }
    }

    &__submit-btn {
        height: 40px;
        width: 268px;
        border: none;
        background: $color-bg-button-fill;
        border-radius: 20px;
        color: $color-text-white;
        margin-top: 21px;
        margin-left: 14px;
        cursor: pointer;

        &:hover {
            transform: scale(1.01);
            box-shadow: $color-box-shadow-loginBtn;
        }
    }
}

.react-date-picker {
    $date-picker: &;

    &__wrapper {
        width: 100%;
        border-radius: 12px;
        color: $color-text-gray;
        background-color: $color-bg-white;
        cursor: pointer !important;
        padding: 2px 15px 2px 15px;
        font-family: $font-family-poppins;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: 12px;
        line-height: 140%;
        outline: none;

        &:hover {
            border: 0.5px solid $color-bg-light-sea-green;
        }
    }

    &__inputGroup {
        width: 90px;

        &__input {
            color: $color-border-gray;
        }
    }

    &__calendar-button {
        svg {
            stroke: $color-border-gray;
            stroke-width: 1;
        }
    }

    &__clear-button {
        svg {
            stroke: $color-border-gray;
            stroke-width: 0.5;
        }
    }

    &--active {
        #{$date-picker}__wrapper {
            background-color: $color-bg-light-sea-green;
            border: 0.5px solid $color-bg-light-sea-green;
            color: $color-text-black;
        }

        #{$date-picker}__inputGroup {
            &__input {
                color: $color-text-black;
            }
        }

        #{$date-picker}__calendar-button {
            svg {
                stroke: $color-text-gray;
                stroke-width: 2;
            }
        }

        .react-date-picker__clear-button {
            svg {
                stroke: $color-text-black;
                stroke-width: 1;
            }
        }
    }
}