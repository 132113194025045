@import "../../../assets/styles/index.scss";

.table {
    width: 100%;
    border-collapse: collapse;

    &__head {
        border-bottom: 0.5px solid $color-border-gray;
        font-weight: $font-weight-medium;
        font-size: 12px;
        line-height: 18px;
        background-color: $color-bg-white;
        height: 50px;
        color: $color-text-gray;
        font-family: $font-family-poppins;
        font-style: normal;
    }

    &__segment {
        width: 10%;
        text-align: start;
        padding-left: 16px;

        &--action {
            min-width: 71px;
            width: 5%;
            text-align: center;
            padding-left: 16px;
            padding-right: 16px;
        }

        &__select-field {
            width: 105px;
            box-sizing: border-box;
            padding: 7px 10px 10px 10px;
            height: 40px;
            background: $color-bg-white;
            border: 1px solid $color-border-slate-gray;
            border-radius: 10px;
            margin-top: 12px;
            margin-bottom: 16px;
            color: $color-text-dark-gray;
            font-family: $font-family-poppins;
            font-style: normal;
            font-weight: $font-weight-regular;
            font-size: 14px;

            &:focus {
                outline: none;
                border: 1px solid $color-border-blue;
            }
        }
    }

    &__select-box {
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        border: 1px solid $color-border-dark-gray;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &--fill {
            background-color: $color-bg-blue;
            color: $color-text-white;
            border: none;
        }
    }

    &__head-element {
        height: 50px;
        display: flex;
        align-items: center;
    }

    &__sort-arrow {
        display: flex;
        flex-direction: column;
        height: 20px;
        color: $color-border-light-gray;
        margin-left: 6px;
        cursor: pointer;

        &__bold {
            color: $color-bg-blue;
        }
    }

    &__raw {
        color: $color-text-gray;
        height: 52px;
        font-weight: $font-weight-regular;
        font-size: 13px;
        line-height: 20px;
        cursor: pointer;
        font-family: $font-family-poppins;
        font-style: normal;

        &:nth-child(even) {
            background-color: $color-bg-off-white;
        }

        &:hover {
            color: $color-text-dark-gray;
            background-color: $color-bg-table-hover;
        }
    }

    &__btnDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 135px;

        &--single {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__delete-btn {
            padding-left: 5px;
            padding-right: 5px;
            height: 27px;
            color: $color-text-gray;
            border: 0.5px solid $color-border-gray;
            border-radius: 12px;
            cursor: pointer;
            background-color: transparent;
            min-width: 65px;

            &:hover {
                border: 0.5px solid $color-bg-red-deep;
                background: $color-bg-red-deep;
                color: $color-text-white;
            }
        }

        &__disable-btn {
            padding-left: 5px;
            padding-right: 5px;
            height: 27px;
            color: $color-text-gray;
            border: 0.5px solid $color-border-gray;
            border-radius: 12px;
            cursor: pointer;
            background-color: transparent;
            min-width: 65px;
        }

        &__details-btn {
            padding-left: 5px;
            padding-right: 5px;
            height: 27px;
            color: $color-text-gray;
            border: 0.5px solid $color-border-gray;
            border-radius: 12px;
            background-color: transparent;
            min-width: 65px;
            cursor: pointer;

            &:hover {
                border: 0.5px solid $color-bg-button-fill;
                background-color: $color-bg-button-fill;
                color: $color-text-white;
            }
        }
    }

    &__confirm-card {
        position: relative;
        height: 0px;
        width: 0px;
        bottom: 38px;
        right: 40px;
    }

    &__confirm-status-card {
        position: relative;
        height: 0px;
        width: 0px;
        right: 25px;
        bottom: 68px;
    }
}