@import "../../assets/styles/index.scss";

.pagination {
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-bg-pagination;

  &__left {
    display: flex;
    align-items: center;
  }

  &__left-text {
    font-weight: $font-weight-regular;
    font-size: 12px;
    color: $color-text-gray;
    margin-left: 16px;
    font-family: $font-family-noto-sans-jp;
  }

  &__dropDown {
    width: 40px;
    height: 28px;
    border: 0.5px solid $color-border-gray;
    border-radius: 8px;
    padding-top: 8px;
    margin-left: 10px;
    padding-left: 13px;
    padding-right: 5px;
    font-weight: $font-weight-regular;
    font-size: 12px;
    color: $color-text-gray;
    background-color: $color-bg-white;
    font-family: $font-family-noto-sans-jp;
    cursor: pointer;

    &__count-container {
      padding-bottom: 30px;
    }
  }

  &__drop-icon {
    width: 10px;
    height: 6.25px;
    margin-left: 14px;
    cursor: pointer;
  }

  &__page-count {
    position: relative;
    top: 13px;
    left: -15px;
    width: 63px;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    background: $color-bg-white;
    box-shadow: $color-box-shadow-filter;
    border-radius: 12px;
    list-style: none;
  }

  &__count-list {
    line-height: 25px;
    padding-left: 15px;
    font-family: $font-family-noto-sans-jp;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: $color-border-light-gray;
      color: $color-text-black;
    }
  }

  &__right {
    max-width: fit-content;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $color-text-gray;
    justify-content: space-between;
    margin-right: 9.8px;
    font-family: $font-family-poppins;
    font-style: normal;
    font-weight: $font-weight-medium;
  }

  &__arrow {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--last {
      margin-left: 5px;
    }

    &:hover:not(.active) {
      background-color: $color-bg-light-gray;
      border-radius: 6px;
    }

    &__img {
      width: 6px;
      height: 10px;
    }
  }

  &__page-number {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
      background-color: $color-bg-light-gray;
      border-radius: 6px;
      color: $color-text-black;
    }

    &__active {
      background-color: $color-bg-white;
      border: 0.5px solid $color-border-gray;
      border-radius: 6px;
    }
  }

  &__pageDot {
    width: 28px;
    height: 28px;
    text-align: center;
    padding-top: 8px;
  }
}