@import "../../../assets/styles/index.scss";

.delete-card {
  width: 160px;
  padding-top: 10px;
  padding-bottom: 5px;
  background: $color-bg-white;
  box-shadow: $color-box-shadow-filter;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
  font-family: $font-family-poppins;
  font-style: normal;
  font-size: 14px;
  color: $color-text-fade-black;

  &__btn-div {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__deleteBtn {
      width: 100%;
      border-top: 0.5px solid $color-border-light-gray;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
      color: $color-text-red;
      font-family: $font-family-poppins;
      font-style: normal;
      font-size: 13px;
      text-align: center;

      &:hover {
        background-color: $color-bg-off-white;
      }
    }

    &__changeBtn {
      width: 100%;
      border-top: 0.5px solid $color-border-light-gray;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
      color: $color-text-green;
      font-family: $font-family-poppins;
      font-style: normal;
      font-size: 13px;
      text-align: center;

      &:hover {
        background-color: $color-bg-off-white;
      }
    }

    &__cancelBtn {
      width: 100%;
      border-top: 0.5px solid $color-border-light-gray;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      cursor: pointer;
      color: $color-text-gray;
      font-family: $font-family-poppins;
      font-style: normal;
      font-size: 13px;
      text-align: center;

      &:hover {
        background-color: $color-bg-off-white;
      }
    }
  }
}