@import "../../assets/styles/index.scss";

.policy {
  margin: 2rem;

  header {
    text-align: center;
    font-size: 3rem;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
  }
  .policy__date {
    //font-weight: $font-weight-bold;
    opacity: .6;
    text-decoration: underline;
  }
  section {
    margin-top: 4rem;

    h2 {
      font-size: 1.3rem;
      font-weight: $font-weight-medium;
      margin-top: 2rem;
    }
  }
}