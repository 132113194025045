$color-text-black: #1f2638;
$color-text-fade-black: #2d3748;
$color-text-navy-black: #0a0d25;
$color-text-red: red;
$color-text-white: #ffffff;
$color-text-gray: #718096;
$color-text-dark-gray: #404752;
$color-text-light-gray: #7c7e88;
$color-text-lighter-gray: rgba(113, 128, 150, 0.7);
$color-text-dark-blue: rgba(63, 109, 225, 1);
$color-text-blue: #0099ff;
$color-text-sea-green:#06cfc7;
$color-text-green:#0BDA51;

$color-bg-question-option: rgba(64, 71, 82, 0.5);
$color-bg-table-hover: rgba(0, 153, 255, 0.1);
$color-bg-modal: rgba(0, 0, 0, 0.3);
$color-bg-body: #f2f8f8;
$color-bg-white: #ffffff;
$color-bg-off-white: #f8f8f8;
$color-bg-blue: #0099ff;
$color-bg-off-blue: #87c2e9;
$color-bg-extra-light-blue: #0099ff2f;
$color-bg-red: #e95060;
$color-bg-red-deep: #ff001b;
$color-bg-light-gray: #e1e1ef;
$color-bg-linearProgress-bar: rgba(113, 128, 150, 0.2);
$color-bg-linearProgress: #12c2e9;
$color-bg-light-sea-green:#56dfdc;
$color-bg-lighter-sea-green:#33f0ed;
$color-bg-button-fill:#06cfc7;
$color-bg-button-inActive:#70dad6;
$color-bg-pagination: #f2f8f8;
$color-bg-sidebar:#494D5F;
$color-bg-sidebar-hover:#707174;

$color-border-gray: #718096;
$color-border-dark-gray: rgba(113, 128, 150, 0.5);
$color-border-slate-gray: #cdcdcd;
$color-border-light-gray: #d0d0d0;
$color-border-lighter-gray: rgba(205, 205, 205, 0.5);
$color-border-blue: #0099ff;

$color-box-shadow-loginBtn: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$color-box-shadow-sidebar: 0px 0px 20px rgba(0, 0, 0, 0.05);
$color-box-shadow-filter: 0px 4px 20px rgba(0, 0, 0, 0.15);
$color-box-shadow-icon-div: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
$color-box-shadow-login: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$color-box-shadow-search:inset rgba(0, 0, 0, 0.16) 0px 1px 4px;
$color-box-shadow-filter-btn:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$color-box-shadow-header:rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
